<template>
  <TransitionRoot appear :show="auth_modal" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-[99]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-50" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform rounded-2xl bg-white dark:bg-neutral-900 p-6 text-left align-middle shadow-xl transition-all dark:text-neutral-50"
            >
              <button
                class="absolute sm:top-0 sm:-right-8 -top-6 right-0 cursor-pointer"
                @click="closeModal"
              >
                <Icon name="ic:sharp-close" class="text-white" size="26" />
              </button>
              <DialogTitle
                as="h3"
                class="text-2xl text-center font-semibold leading-6 text-gray-900 dark:text-neutral-50 py-6"
              >
                <Transition
                  enter-class="duration-300 ease-out"
                  enter-from-class="opacity-0 scale-95"
                  enter-to-class="opacity-100 scale-100"
                  leave-class="duration-200 ease-in"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-95"
                >
                  <span v-if="!auth_step">
                    {{ $t("sign_in") }} <span class="font-medium">/</span>
                    {{ $t("sign_up") }}
                  </span>
                  <span v-else-if="auth_step == 'login'">
                    {{ $t("sign_in") }}
                  </span>
                  <span v-else-if="auth_step == 'register'">
                    {{ $t("sign_up") }}
                  </span>
                </Transition>
              </DialogTitle>
              <GeneralAuthSocialite />
              <div class="relative text-center mx-auto max-w-xs my-6">
                <span
                  class="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:bg-neutral-900"
                  >{{ $t("or") }}</span
                >
                <div
                  class="absolute dark:border-neutral-800 left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100"
                ></div>
              </div>
              <template v-if="!auth_step">
                <div class="flex flex-col gap-y-3 mx-auto max-w-xs">
                  <input
                    type="email"
                    v-model="email"
                    class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
                    placeholder="example@example.com"
                  />
                  <ElementsButtonSubmit
                    :loading="loading"
                    @click="NextStepAuth"
                  >
                    {{ $t("continue") }}
                  </ElementsButtonSubmit>
                </div>
              </template>
              <template v-else-if="auth_step == 'login'">
                <GeneralLogin />
                <div class="text-center mt-6">
                  <p>
                    {{ $t("dont_have_account") }}
                    <span
                      @click="auth_step = 'register'"
                      class="font-semibold cursor-pointer hover:text-primary-500"
                      >{{$t('sign_up')}}</span
                    >
                  </p>
                  <p
                    @click="ForgotPassword"
                    class="text-sm mt-4 hover:text-primary-500 cursor-pointer"
                  >
                    {{ $t("forgot_your_password") }}
                  </p>
                </div>
              </template>
              <template v-else-if="auth_step == 'register'">
                <GeneralRegister />
                <div class="text-center mt-6">
                  {{ $t("already_account") }}
                  <span
                    @click="auth_step = 'login'"
                    class="font-semibold cursor-pointer hover:text-primary-500"
                    >{{ $t("sign_in") }}</span
                  >
                </div>
              </template>
              <div class="mt-6 text-center text-xs max-w-sm mx-auto">
                <p>
                  {{ $t("term_auth", { app_name: app_name }) }}
                </p>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useConfigStore } from "~/stores/config";
import { useAuthStore } from "~/stores/auth";
import { storeToRefs } from "pinia";
const store = useConfigStore();
const { auth_modal, auth_step } = storeToRefs(store);
const app_name = useRuntimeConfig().public.app_name;
const closeModal = () => {
  auth_modal.value = false;
};
const { loginForm, registerForm } = storeToRefs(useAuthStore());
const email = ref("");
const loading = ref(false);
const NextStepAuth = () => {
  loading.value = true;
  useApiFetch("/api/auth/check", {
    body: JSON.stringify({
      email: email.value,
    }),
    method: "POST",
  })
    .then((res) => {
      if (res.data.value) {
        loginForm.value.email = email.value;
        auth_step.value = "login";
      } else {
        registerForm.value.email = email.value;
        auth_step.value = "register";
      }
      loading.value = false;
    })
    .catch((err) => {
      loading.value = false;
    });
};
const ForgotPassword = () => {
  auth_modal.value = false;
  useRouter().push(useLocalePath()("/forgot-password"));
};
</script>
