<template>
  <NuxtLoadingIndicator />
  <VitePwaManifest />
  <GeneralAuthenticatedModal />
  <Lenis root class="dark:bg-neutral-900/95">
    <V2Header :places="layoutData?.places"></V2Header>
    <div class="text-base text-neutral-900 dark:text-neutral-200 font-body">
      <slot />
      <LayoutsFooter :places="layoutData?.places" />
    </div>
  </Lenis>
</template>
<script lang="ts" setup>
import type { ResponseDataLayout } from "~/types/Layout";
import { storeToRefs } from "pinia";
import { useColorStore } from "~/stores/color";
import { useConfigStore } from "~/stores/config";
import { useLayoutStore } from "~/stores/layout";

const { mode } = storeToRefs(useColorStore());
const { search_modal } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { layoutData } = storeToRefs(useLayoutStore());
const { data } = await useAsyncData(async () => {
  const res: ResponseDataLayout = await $fetch("/api/layout/data", {
    body: {
      locale: locale.value,
    },
    method: "post",
  });
  return res;
});
layoutData.value = data.value as unknown as ResponseDataLayout;

watch(mode, (newValue, oldValue) => {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("color_mode", newValue);
    document.querySelector("html")?.classList.remove(oldValue);
    document.querySelector("html")?.classList.add(newValue);
  }
});

const InitEventSearch = () => {
  document.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.key === "k") {
      onCtrlKPress(event);
    }
  });
};

const onCtrlKPress = (event: KeyboardEvent) => {
  search_modal.value = !search_modal.value;
  event.preventDefault();
};
onMounted(() => {
  InitEventSearch();
});
const meta = useMeta();

useHead({
  meta: [
    { name: "application-name", content: "AttractionsVietnam" },
    { name: "theme-color", content: "#2cbcff" },
    {
      name: "msapplication-TileColor",
      content: "#2cbcff",
    },
    {
      name: "msapplication-TileImage",
      content: "/ms-icon-144x144.png",
    },
    {
      name: "agd-partner-manual-verification",
      content: "",
    },
  ],
  link: [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/icon-180x180.png",
    },
    {
      rel: "apple-touch-icon-precomposed",
      sizes: "180x180",
      href: "/icon-180x180.png",
    },
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    },
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: "/apple-icon-57x57.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: "/apple-icon-60x60.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: "/apple-icon-72x72.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: "/apple-icon-76x76.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: "/apple-icon-114x114.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: "/apple-icon-120x120.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: "/apple-icon-144x144.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "/apple-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/icon-180x180.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "/android-icon-192x192.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "/favicon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
  ],
});

// set meta seo
useSchemaOrg([
  defineOrganization({
    name: "Attractions Vietnam",
    sameAs: [
      "https://twitter.com/AttractionsVn",
      "https://www.facebook.com/attractionsvietnam/",
      "https://www.instagram.com/planet.attractions/",
      "https://www.tiktok.com/@planetexplorerr",
    ],
    image: meta.assetImage("/images/og-default.png"),
    url: meta.currentHost(),
  }),
  defineWebSite({
    name: "Attractions Vietnam",
    url: meta.currentHost(),
    inLanguage: meta.inLanguages(),
    description: "Attractions Vietnam",
  }),
]);
</script>
<style></style>
